









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class IconSelectArrowUpDown extends Vue {
  @Prop({ type: Boolean, default: false }) isOpen: boolean;
}
