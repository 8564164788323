import { render, staticRenderFns } from "./speaker.html?vue&type=template&id=328a80b4&scoped=true&"
import script from "./speaker.vue?vue&type=script&lang=ts&"
export * from "./speaker.vue?vue&type=script&lang=ts&"
import style0 from "./speaker.scss?vue&type=style&index=0&id=328a80b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328a80b4",
  null
  
)

export default component.exports