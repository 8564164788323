var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"program-list",style:({
    'grid-template-columns': _vm.programGridTemplateColumnsCSSRule,
  })},_vm._l((_vm.programSlots),function(slot,slotIndex){return _c('div',{key:'programSlot_' + slot.conferenceRoomId + '_' + new Date(slot.dateStart).getTime().toFixed(0) + '_' + slotIndex,staticClass:"program-slot",class:{
      'program-slot-future': _vm.isProgramSlotFuture(slot),
      'program-slot-in-empty-row': _vm.isProgramSlotInEmptyRow(slotIndex),
    }},[_c('div',{staticClass:"program-slot-date",style:({
        visibility: _vm.isProgramDayDifferentFromPrevious(slot, slotIndex) ? null : 'hidden',
      })},[_c('span',{staticClass:"clickable-text",on:{"click":function($event){return _vm.onProgramSlotDateClick(slot.dateStart)}}},[_vm._v(" "+_vm._s(_vm.$moment(slot.dateStart).format('dddd, D MMMM, YYYY'))+" ")])]),_vm._l((slot.programs),function(program){return _c('div',{key:program.id,staticClass:"card-wrapper",class:{
        'program-list-card-wrapper-past': _vm.isProgramCardPast(program),
      }},[(program)?_c('program-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisibleInCurrentViewType(program)),expression:"isVisibleInCurrentViewType(program)"}],attrs:{"program-slot":slot,"program":program,"card-color":_vm.roomColors[slot.conferenceRoomId],"disable-dynamic-height":""},on:{"isFavoriteChange":function($event){return _vm.onProgramCardIsFavoriteChange($event, program)}},nativeOn:{"click":function($event){return _vm.onProgramCardClick($event, program)}}}):_vm._e()],1)})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }