


import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EwCheckbox from '@/_modules/standalone-company/components/UI/ew-checkbox/ew-checkbox.vue';
import IconSelectArrowUpDown from '@/_modules/icons/components/icon-select-arrow-up-down.vue';
import { IZoneSelect } from '@/_modules/promo-program-new/components/program-viewer/program-viewer.vue';

@Component({
  components: {
    EwCheckbox,
    IconSelectArrowUpDown,
  }
})
export default class EwSelect extends Vue {

  public isFocused: boolean = false;
  public selectedIds: string[] = [];
  public checkedIds: { string: boolean }[] = [];
  public selected: IZoneSelect = null;

  @Prop({ type: Array, default: (): string[] => [] })
  public readonly selectData: IZoneSelect[];

  @Prop({ type: Boolean, default: false })
  public readonly multiple: boolean;

  @Watch('selectData', {immediate: true})
  public onSelectedDateChanged(): void {
    if (!this.selectData) { return; }
    this.selectData.forEach(x => {
      if (x.isSelected) {
        this.selected = x;
      }
    });
    this.$emit('selected', this.selected);
  }

  public onSelectFocus(): void {
    this.isFocused = true;
  }

  public onSelectBlur(): void {
    this.isFocused = false;
  }

  public onToggleSelection(): void {
    this.selectedIds = [];
    for (const [key, value] of Object.entries(this.checkedIds)) {
      if (value) {
        this.selectedIds.push(key);
      }
    }

    this.$emit('selectedIds', this.selectedIds);
  }

  public isDisabled(id: number): boolean {
    return this.selectedIds.length > 2 ? !this.checkedIds[id] : !!this.selectedIds[id];
  }

  public onSelected(data: any): void {
    this.selected = data;
    this.$emit('selected', data);
  }

}
