


import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import UtilsHelper from '@/_helpers/utils.helper';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import timezoneInfo from '@/_modules/events/components/timezones.json';
import { TTimezoneInfo } from '@/_types/timezone-info.type';
import ProgramCard from '@/_modules/promo-program-new/components/program-card/program-card.vue';
import EwCalendar from '@/_modules/ew-calendar/ew-calendar.vue';
import { TScheduleDay } from '@/_modules/ew-calendar/types/ew-calendar.type';
import ChevronLeft from '@/_modules/icons/components/ew-calendar/chevron-left.vue';
import IconChevronRight from '@/_modules/icons/components/ew-calendar/chevron-right.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import { TAddFloatingProgramCardParams } from '@/_modules/promo-program/store/promo-program.store';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import { TTab } from '@/_ui/tabs/types/tabs.type';
import SegmentControl from '@/_ui/segment-control/segment-control.vue';
import IconEwStar from '@/_modules/icons/components/icon-ew-star.vue';
import EwSelect from '@/_modules/standalone-company/components/UI/ew-select/ew-select.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';
import momentTimezone from 'moment-timezone';
import _isEqual from 'lodash.isequal';
import EwSelectCalendar from '@/_modules/standalone-company/components/UI/ew-select-calendar/ew-select-calendar.vue';
import IconEwList from '@/_modules/icons/components/icon-ew-list.vue';
import IconEwProgramViewModeCalendar from '@/_modules/icons/components/icon-ew-program-view-mode-calendar.vue';
import ProgramList from '@/_modules/promo-program-new/components/program-list/program-list.vue';
import PageTitle from '@/_components/page-title/page-title.vue';
import ResponsivenessHelper from '@/_helpers/responsiveness.helper';
import ModuleHasNoItems from '@/_components/module-has-no-items/module-has-no-items.vue';
import IconProgram from '@/_modules/icons/components/sidebar/icon-program.vue';
import EventHelper from '@/_helpers/event.helper';
import {TContact} from '@/_types/contact.type';

export const BASE_PROGRAM_SLOT_MAX_WIDTH = 500;
export const BASE_PROGRAM_SLOT_HEIGHT = 56; // TODO: expose full height and empty slot height to CSS variables for access from scss and for responsiveness
export const PROGRAM_SLOT_DURATION_MINUTES = 15;

export type TProgramSlot = {
  dateStart: Date;
  conferenceRoomId: number;
  conferenceRoomTitle: string;
  program?: TConferenceProgram;
  programs?: TConferenceProgram[];
  isOverlappedByAnotherSession?: boolean;
}

export interface IZoneSelect extends TTimezoneInfo {
  id: number;
  title: string;
  isSelected: boolean;
}

@Component({
  components: {
    PageTitle,
    ProgramCard,
    EwCalendar,
    ChevronLeft,
    IconChevronRight,
    EwButton,
    SearchBar,
    SegmentControl,
    IconEwStar,
    EwSelect,
    EwSelectCalendar,
    IconEwList,
    IconEwProgramViewModeCalendar,
    ProgramList,
    ModuleHasNoItems,
    IconProgram,
  }
})
export default class ProgramViewer extends Vue {

  @Ref('programViewerBody') programViewerBody: HTMLElement;
  @Getter('promoProgramStore/isLoading') isProgramLoading: boolean;
  @Getter('promoProgramStore/conferenceRooms') conferenceRooms: TConferenceRoom[];
  @Getter('promoProgramStore/lastError') lastError: Error;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') readonly myself: TContact;
  @Getter('_eventStore/selectedTimezone') selectedTimezoneGetter: string;
  @Action('promoProgramStore/addFloatingProgram') addFloatingProgramCard: (params: TAddFloatingProgramCardParams) => void;
  @Action('calendarStore/setMarkedDates') setMarkedDates: (params: string[]) => void;
  @Action('_eventStore/setSelectedTimezone') setSelectedTimezone: (params: string) => void;

  public currentPageNumber: number = 0;
  public programSlots: TProgramSlot[] = [];
  public selectedDate: string = '';
  public filteredConferenceRooms: TConferenceRoom[] = [];
  public timeZoneSelection: IZoneSelect[] & TTimezoneInfo[] = [];
  public selectedTimezone: string = '';
  public hasViewTypeModeCheckFired: boolean = false;
  public numberOfColumnsInPage: number = ResponsivenessHelper.getNumberOfColumnsFittingInTheView(BASE_PROGRAM_SLOT_MAX_WIDTH);

  public programViewTypeTabs: TTab[] = [
    {
      title: this.$t('navigation.list'),
      isActive: true,
      index: 0,
    },
    {
      title: this.$t('navigation.map'),
      isActive: false,
      index: 1,
    },
  ];

  public get noItemsText(): string {
    return (this.isListTypeMy ? this.$t('conferenceHallMyIsEmpty') : this.$t('conferenceHallIsEmpty')) as string;
  }

  public get maxPageNumber(): number {
    if (!this.filteredConferenceRooms) {
      return 0;
    }
    return Math.ceil(this.filteredConferenceRooms.length / this.numberOfColumnsInPage);
  }

  public get isPrevNextPageControlNeeded(): boolean {
    return this.maxPageNumber > 1;
  }

  public get isPrevPageButtonDisabled(): boolean {
    return !this.isPrevNextPageControlNeeded || this.currentPageNumber <= 0;
  }

  public get isNextPageButtonDisabled(): boolean {
    return !this.isPrevNextPageControlNeeded || this.currentPageNumber >= this.maxPageNumber - 1;
  }

  public get formattedDate(): string {
    return (this.selectedDate && this.$moment(this.selectedDate).format('DD MMMM YYYY')) || '';
  }

  public get isSelectedDateToday(): boolean {
    if (!this.selectedDate) {
      return false;
    }
    const today = new Date();
    const selectedDate = this.$moment(this.selectedDate).toDate();
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);
    return today.getTime() === selectedDate.getTime();
  }

  public get roomColors(): { [confRoomId: number]: string } {
    if (!this.filteredConferenceRooms) {
      return {};
    }

    const result: { [confRoomId: number]: string } = {};
    this.filteredConferenceRooms.forEach(confRoom => {
      result[confRoom.id] = UtilsHelper.stringToHSL(confRoom.title + confRoom.id);
    });

    return result;
  }

  public get columnsOfProgramPage(): TConferenceRoom[] {
    if (!this.filteredConferenceRooms) {
      return [];
    }

    const viewStart: number = this.currentPageNumber * this.numberOfColumnsInPage;
    const viewEnd: number = viewStart + this.numberOfColumnsInPage;

    return this.filteredConferenceRooms.slice(viewStart, viewEnd);
  }

  public get eventTimeZone(): string {
    return (this.event && this.event.time_region && this.getEventTimeRegionFormatted()) || '';
  }

  public get timeZoneSelectData(): IZoneSelect[] {
    return this.timeZoneSelection;
  }

  public get eventTimezoneInfo(): TTimezoneInfo {
    if (!this.selectedTimezone) {
      return null;
    }
    return (timezoneInfo as TTimezoneInfo[]).find(tz => {
      return !!tz.utc.find(tzName => tzName === this.selectedTimezone);
    });
  }

  public findTimezoneByTimeRegion(utc: string): TTimezoneInfo {
    if (!this.selectedTimezone) {
      return null;
    }
    return (timezoneInfo as TTimezoneInfo[]).find(tz => {
      return !!tz.utc.find(tzName => tzName === utc);
    });
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isEventOrganizer(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.myself);
  }

  public get isEventEditor(): boolean {
    return !this.isEventOrganizer && EventHelper.isContactEventEditor(this.event, this.myself);
  }

  public get isNoItemsActionSlotVisible(): boolean {
    return !this.isListTypeMy && (this.isEventOrganizer || this.isEventEditor);
  }

  public created(): void {
    this.$store.dispatch('_eventStore/getEvent', this.eventId);
    this.selectedTimezone = momentTimezone.tz.guess();
    this.setSelectedTimezone(this.selectedTimezone);
  }

  public mounted(): void {

    this.initDateAndSlots();

    this.$watch('event', () => {
      this.initDateAndSlots();
      this.initTimeZoneSelectOptions();
      this.startInCondensedMode();
    });

    this.$watch('conferenceRooms', (newVal, oldVal) => {
      if (!_isEqual(newVal, oldVal)) {
        this.fillMarkedDates();

        this.filteredConferenceRooms = (this.conferenceRooms || []);
        const date = new Date(this.selectedDate);
        this.createProgramSlots(date.toString());
      }
    }, { immediate: true });

    this.$watch('selectedTimezoneGetter', () => {
      this.selectedTimezone = this.selectedTimezoneGetter;
    });

    this.startInCondensedMode();

    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  public startInCondensedMode(): void {

    window.setTimeout(() => {
      const condensedModeTab: TTab = this.programViewTypeTabs.find(tab => tab.index === 1);
      if (!condensedModeTab || !this.conferenceRooms) {
        return;
      }
      this.hasViewTypeModeCheckFired = true;
      this.onProgramViewTypeTabClick(condensedModeTab, 1);
    }, 200);

  }

  public beforeDestroy(): void {
    this.setMarkedDates([]);
    window.removeEventListener('resize', this.onResize);
  }

  public initDateAndSlots(): void {
    this.selectedDate = new Date(new Date().setHours(0, 0, 0, 0)).toString();
    if (this.conferenceRooms && (!this.filteredConferenceRooms || !this.filteredConferenceRooms.length)) {
      this.filteredConferenceRooms = (this.conferenceRooms || []);
      this.createProgramSlots(this.selectedDate);
    }
  }

  public initTimeZoneSelectOptions(): void {
    if (!this.event) {
      return;
    }

    let timeZoneSelectionLastItem;
    this.timeZoneSelection.push(this.findTimezoneByTimeRegion(this.event.time_region));
    timeZoneSelectionLastItem = this.timeZoneSelection[this.timeZoneSelection.length - 1];
    timeZoneSelectionLastItem.title = `${this.$t('select.timezone.event')} ${timeZoneSelectionLastItem.text}`;
    timeZoneSelectionLastItem.isSelected = false;
    timeZoneSelectionLastItem.id = 1;

    this.timeZoneSelection.push(this.findTimezoneByTimeRegion(momentTimezone.tz.guess()));
    timeZoneSelectionLastItem = this.timeZoneSelection[this.timeZoneSelection.length - 1];
    timeZoneSelectionLastItem.title = `${this.$t('select.timezone.local')} ${timeZoneSelectionLastItem.text}`;
    timeZoneSelectionLastItem.isSelected = true;
    timeZoneSelectionLastItem.id = 2;
  }

  public programListTypeTabs: TTab[] = [
    {
      title: this.$t('navigation.all'),
      isActive: true,
      index: 0,
    },
    {
      title: this.$t('navigation.my'),
      isActive: false,
      index: 1,
    },
  ];

  public changeTimeZone(date: Date | string): Date {
    let processedDate: Date;
    if (typeof date === 'string') {
      processedDate = new Date(date);
    } else {
      processedDate = date;
    }

    return new Date(processedDate.toLocaleString('en-US', {
      timeZone: this.selectedTimezone,
    }));
  }

  public onProgramListTypeActiveTabUpdate(activeTab: TTab, activeTabIndex: number): void {
    this.programListTypeTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });
  }

  public getEventTimeRegionFormatted(): string {
    if (!this.eventTimezoneInfo) {
      return '';
    }

    return (this.eventTimezoneInfo.abbr || '') + '<br />' + (this.eventTimezoneInfo.text || '')
      .split(/\s+/)[0]
      .replace(/[()]/g, '') // remove ( and )
      .replace(':00', '') // remove zero minutes
      .replace(/([+-])0/, '$1'); // remove leading zero from hours
  }

  public onPrevPageButtonClick(): void {
    if (this.currentPageNumber === 0) {
      return;
    }
    this.currentPageNumber--;
    this.createProgramSlots(this.selectedDate);
  }

  public onNextPageButtonClick(): void {
    const maxPageNumber: number = Math.floor(this.filteredConferenceRooms.length / this.numberOfColumnsInPage);
    if (this.currentPageNumber === maxPageNumber) {
      return;
    }
    this.currentPageNumber++;
    this.createProgramSlots(this.selectedDate);
  }

  public isTimeSlotMinutesLineVisible(minutes: number): boolean {
    return minutes % 30 === 0;
  }

  public isTimeSlotTimeVisible(programSlot: TProgramSlot, programSlotIndex: number): boolean {
    return (programSlotIndex % this.adaptiveNumberOfColumnsInPage === 0)
      && (this.isTimeSlotMinutesLineVisible(programSlot.dateStart.getMinutes()));
  }

  public getProgramSlotTimeFormatted(programSlot: TProgramSlot): string {
    const hours: number = programSlot.dateStart.getHours();
    const minutes: number = programSlot.dateStart.getMinutes();

    return [
      hours.toFixed(0).padStart(2, '0'),
      minutes.toFixed(0).padStart(2, '0'),
    ].join(':');
  }

  public createProgramSlots(date: string): void {
    if (!this.columnsOfProgramPage.length) {
      return;
    }

    this.programSlots = [];
    const numberOfSlotsInDay = (24 * (60 / PROGRAM_SLOT_DURATION_MINUTES));
    const abstractDate = new Date(date);
    abstractDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < numberOfSlotsInDay; i++) {
      for (let j = 0; j < this.adaptiveNumberOfColumnsInPage; j++) {
        const columnOfPage = this.columnsOfProgramPage[j];

        this.programSlots.push({
          dateStart: new Date(abstractDate),
          conferenceRoomId: (columnOfPage && columnOfPage.id) || null,
          conferenceRoomTitle: '',
          program: null,
          isOverlappedByAnotherSession: false,
        });
      }

      abstractDate.setUTCMinutes(abstractDate.getUTCMinutes() + PROGRAM_SLOT_DURATION_MINUTES);
    }

    this.fillProgramSlots();
    this.markOverlappedProgramSlots();
    this.dispatchScroll();
  }

  public isSessionOverlappedFromPreviousDate(programSlot: TProgramSlot, session: TConferenceProgram): boolean {
    const slotStart: Date = (programSlot && programSlot.dateStart) || null;
    const programStartDate: Date = (session && this.changeTimeZone(new Date(session.date_start))) || null;
    const programEndDate: Date = (session && this.changeTimeZone(new Date(session.date_end))) || null;
    const isFirstSlot: boolean = slotStart && (slotStart.getHours() === 0 && slotStart.getMinutes() === 0);
    const isSessionStartBefore: boolean = programStartDate && programStartDate.getTime() < slotStart.getTime();
    const isSessionEndAfter: boolean = programEndDate && programEndDate.getTime() > slotStart.getTime();

    return slotStart && isFirstSlot && isSessionStartBefore && isSessionEndAfter;
  }

  public fillProgramSlots(): void {
    for (let j = 0; j < this.adaptiveNumberOfColumnsInPage; j++) {
      if (!this.columnsOfProgramPage[j]) { break; }
      const programs = this.columnsOfProgramPage[j].programs;

      for (const program of programs) {
        const slots = this.programSlots.filter(item => item.conferenceRoomId === program.conference_id);
        for (const slot of slots) {
          if (+slot.dateStart === +program.date_start) {
            const programDate = this.changeTimeZone(new Date(program.date_start));
            const isStartTimeSameToSlot: boolean = slot.dateStart && programDate.getTime() === slot.dateStart.getTime();
            if (isStartTimeSameToSlot || this.isSessionOverlappedFromPreviousDate(slot, program)) {
              slot.program = program;
              slot.conferenceRoomTitle = this.columnsOfProgramPage[j].title;
            }
          }
        }
      }
    }
  }

  public markOverlappedProgramSlots(): void {
    this.programSlots.forEach((slot, index) => {
      if (!slot.program) {
        return; // continue forEach
      }
      const programStartDate = new Date(slot.program.date_start);
      let programStart: Date = programStartDate;
      if (slot.dateStart.getTime() > programStartDate.getTime()) {
        programStart = slot.dateStart;
      }
      const programEnd = new Date(slot.program.date_end);
      const programMinutes = (programEnd.getTime() - programStart.getTime()) / 1000 / 60;
      const programSlotSpan = programMinutes / PROGRAM_SLOT_DURATION_MINUTES;
      for (let i = 0; i < programSlotSpan; i++) {
        const overlappedSlotIndex = index + i * this.adaptiveNumberOfColumnsInPage;
        if (!this.programSlots[overlappedSlotIndex]) {
          continue;
        }
        this.programSlots[overlappedSlotIndex].isOverlappedByAnotherSession = true;
      }
    });
  }

  public onDateChoiceChange(chosenDay: TScheduleDay): void {
    const dateString: string = chosenDay.fullDate.toString();
    if (this.selectedDate === dateString) {
      return;
    }
    this.selectedDate = dateString;
    this.createProgramSlots(this.selectedDate);
  }

  public onProgramCardClick(e: PointerEvent, programSlot: TProgramSlot): void {
    const left: number = e.clientX;
    const top: number = e.clientY;
    const firstProgram: TConferenceProgram = programSlot.program;
    if (ResponsivenessHelper.isScreenWidthLessOrEqualTo(800)) {
      this.navigateToProgramPage(firstProgram);
    } else {
      this.addFloatingProgramCard({
        top,
        left,
        program: firstProgram,
      });
    }
  }

  public navigateToProgramPage(program: TConferenceProgram): void {
    if (!program || !program.date_start) {
      return;
    }
    this.$router.push({
      name: 'promo-program-page',
      params: {
        eventId: '' + this.eventId,
        programDate: '' + DateTimeHelper.dateToStringAsYMD(new Date(program.date_start)),
        programId: '' + program.id,
      }
    });
  }

  public getProgramSlotClasses(programSlot: TProgramSlot, index: number): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    result['program-slot-empty'] = (!programSlot.program && this.isProgramSlotRowEmpty(index));
    result['program-slot-past'] = this.isProgramSlotPast(programSlot);

    if (this.conferenceRooms &&
      index >= this.filteredConferenceRooms.length &&
      programSlot.dateStart &&
      this.isTimeSlotMinutesLineVisible(programSlot.dateStart.getMinutes())
    ) {
      result['program-slot-has-border-top'] = true;
    }

    return result;
  }

  public isProgramSlotRowEmpty(index: number): boolean {
    const fromIndex: number = index - index % this.adaptiveNumberOfColumnsInPage;
    const toIndex: number = fromIndex + this.adaptiveNumberOfColumnsInPage;
    let result = true;

    for (let i = fromIndex; i < toIndex; i++) {
      if (this.programSlots[i].program || this.programSlots[i].isOverlappedByAnotherSession) {
        result = false;
        break;
      }
    }

    return result;
  }

  public isProgramSlotPast(programSlot: TProgramSlot): boolean {
    const now: Date = new Date();
    now.setSeconds(0, 0);
    const slotEndDate: Date = new Date(programSlot.dateStart);
    slotEndDate.setMinutes(slotEndDate.getMinutes() + PROGRAM_SLOT_DURATION_MINUTES);
    return now.getTime() >= slotEndDate.getTime();
  }

  @Watch('isListTypeMy')
  public onIsListTypeMyChange(): void {
    this.dispatchScroll();
  }

  public get isListTypeMy(): boolean {
    return this.programListTypeTabs[1].isActive;
  }

  public isFavoriteOnly(programSlot: TProgramSlot): boolean {
    if (this.isListTypeMy) {
      return programSlot.program.is_favorite;
    }
    return true;
  }

  public onProgramCardIsFavoriteChange(newVal: boolean, programSlot: TProgramSlot): void {
    programSlot.program.is_favorite = newVal;
  }

  public onSelectedSectionsChanged(selectedIds: string[]): void {
    if (!selectedIds.length) {
      this.filteredConferenceRooms = (this.conferenceRooms || []);
      this.createProgramSlots(this.selectedDate);
      return;
    }

    this.filteredConferenceRooms = (this.conferenceRooms || []).filter(item => {
      return selectedIds.find(id => {
        return item.id === +id;
      });
    });

    this.createProgramSlots(this.selectedDate);
  }

  public getProgramSlotRefName(programSlot: TProgramSlot): string {
    return [
      'programSlot',
      (programSlot && programSlot.conferenceRoomId ? programSlot.conferenceRoomId.toFixed(0) : '-1'),
      DateTimeHelper.dateToStringAsYMD(programSlot.dateStart),
      DateTimeHelper.dateToHoursAndMinutes(programSlot.dateStart),
    ].join('_');
  }

  public dispatchScroll(): void {
    this.$nextTick(() => {
      if (!this.columnsOfProgramPage.length || !this.programSlots.length) {
        return;
      }

      if (this.isSelectedDateToday) {
        this.scrollToGivenSlot(this.isListTypeMy ? this.getFirstSessionSlot() : this.getCurrentHourSlot());
      } else {
        this.scrollToGivenSlot(this.getFirstSessionSlot());
      }
    });
  }

  public getCurrentHourSlot(): HTMLElement {
    if (!this.columnsOfProgramPage.length || !this.programSlots.length) {
      return null;
    }

    const targetSlotRefName = this.getProgramSlotRefName({
      dateStart: ((now: Date): Date => {
        now.setMinutes(0, 0, 0);
        return now;
      })(new Date()),
      conferenceRoomId: (this.columnsOfProgramPage && this.columnsOfProgramPage[0] && this.columnsOfProgramPage[0].id) || -1,
      program: null,
      conferenceRoomTitle: '',
    });

    const targets: HTMLElement[] = this.$refs[targetSlotRefName] as HTMLElement[];
    return (targets && targets[0]) || null;
  }

  public getFirstSessionSlot(): HTMLElement {
    if (!this.columnsOfProgramPage.length || !this.programSlots.length) {
      return null;
    }

    const targetSlot: TProgramSlot = this.programSlots.find(slot => {
      if (this.isListTypeMy) {
        return !!(slot.program && slot.program.is_favorite);
      }
      return !!slot.program;
    }) || this.programSlots[0];

    const targetSlots: HTMLElement[] = (this.$refs[this.getProgramSlotRefName(targetSlot)] as HTMLElement[]) || [];
    return (targetSlots && targetSlots[0]) || null;
  }

  public scrollToGivenSlot(programSlotElement: HTMLElement): void {
    // N.B.: getBoundingClientRect() returns numbers relative to viewport
    if (!programSlotElement) {
      return;
    }
    try {
      const targetRect: DOMRect = programSlotElement.getBoundingClientRect();
      const viewerRect: DOMRect = this.programViewerBody.getBoundingClientRect();
      const viewerCompStyles: CSSStyleDeclaration = window.getComputedStyle(this.programViewerBody);
      const viewerPaddingTop: number = parseInt(viewerCompStyles.getPropertyValue('padding-top'), 10);
      const targetTop: number = targetRect.top - viewerRect.top - viewerPaddingTop + this.programViewerBody.scrollTop;
      this.programViewerBody.scroll({
        left: 0,
        top: targetTop,
        behavior: 'smooth',
      });
    } catch { /* ignore */ }
  }

  public onSelectedTimezoneChange(data: IZoneSelect): void {
    this.timeZoneSelection.forEach(item => {
      item.isSelected = item.id === data.id;
      if (item.isSelected) {
        this.setSelectedTimezone(item.utc[0]);
      }
    });

    this.fillMarkedDates();
    this.selectedDate = this.changeTimeZone(new Date(new Date(this.selectedDate).setHours(0, 0, 0, 0))).toString();
    this.createProgramSlots(this.selectedDate);
  }

  public fillMarkedDates(): void {
    const result: string[] = [];
    (this.conferenceRooms || [])
      .map(room => room.programs)
      .reduce((acc, curVal) => {
        return acc.concat(curVal);
      }, [])
      .forEach(program => {
        const dateStart = new Date(new Date(program.date_start).setHours(0, 0, 0, 0)).toISOString();
        const dateEnd = new Date(new Date(program.date_end).setHours(0, 0, 0, 0)).toISOString();
        if (new Date(program.date_start).getDate() < new Date(program.date_end).getDate()) {
          result.push(dateEnd);
        }
        result.push(dateStart);
      });

    result.sort((a: any, b: any) => {
      if (+new Date(a) < +new Date(b)) {
        return -1;
      } else if (+new Date(a) > +new Date(b)) {
        return 1;
      }
      return 0;
    });

    this.setMarkedDates([...new Set(result)]);
  }

  public onProgramViewTypeTabClick(activeTab: TTab, activeTabIndex: number): void {
    this.programViewTypeTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });
  }

  public get isViewModeTimeSlots(): boolean {
    return this.programViewTypeTabs[0].isActive;
  }

  public onProgramListDateChanged(newDate: Date): void {
    this.onProgramViewTypeTabClick(this.programViewTypeTabs[0], 0);
    this.selectedDate = newDate.toString();
    this.createProgramSlots(this.selectedDate);
  }

  public setProgramSlotMaxWidthCustomProp(): void {
    const propVal: string = this.getProgramSlotMaxPixelWidth().toFixed(2) + 'px';
    document.documentElement.style.setProperty('--program-slot-max-width', propVal);
  }

  public getProgramSlotMaxPixelWidth(): number {
    const fallbackValue = 510;
    let result: number;
    try {
      result = this.programViewerBody.getBoundingClientRect().width / this.numberOfColumnsInPage;
    } catch {
      result = fallbackValue;
    }
    return result || fallbackValue;
  }

  public get programGridTemplateColumnsCSSRule(): string {
    const columnsInCurrentPage = this.adaptiveNumberOfColumnsInPage;
    const colFrWidth = (100 / columnsInCurrentPage).toFixed(2) + 'fr';
    return 'repeat(' + columnsInCurrentPage + ', ' + colFrWidth + ')';
  }

  public isResizeDebounced: boolean = false;

  public onResize(): void {
    if (this.isResizeDebounced) {
      return;
    }
    this.isResizeDebounced = true;
    window.requestAnimationFrame(() => {
      this.numberOfColumnsInPage = this.temporaryLimitMaxNumberOfColumnsInPage(3);
      this.setProgramSlotMaxWidthCustomProp();
      // N.B. see watcher
      this.isResizeDebounced = false;
    });
  }

  // TODO: allow for more than 3 columns if the viewport allows for it (currently grid layout breaks on >= 4 columns, if there are less confRooms than columns)
  public temporaryLimitMaxNumberOfColumnsInPage(limitBy: number): number {
    return Math.min(ResponsivenessHelper.getNumberOfColumnsFittingInTheView(BASE_PROGRAM_SLOT_MAX_WIDTH), limitBy);
  }

  @Watch('numberOfColumnsInPage')
  private onNumberOfColumnsInPageChange(): void {
    this.createProgramSlots(this.selectedDate);
  }

  public get adaptiveNumberOfColumnsInPage(): number {
    return Math.min(this.numberOfColumnsInPage, (this.columnsOfProgramPage.length || 1));
  }

}
