


import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  TProgramSlot,
  BASE_PROGRAM_SLOT_HEIGHT,
  PROGRAM_SLOT_DURATION_MINUTES
} from '@/_modules/promo-program-new/components/program-viewer/program-viewer.vue';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import { TContact } from '@/_types/contact.type';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { TFloatingProgramCard } from '@/_modules/promo/types/floating-program-card.type';
import Speaker from '@/_modules/promo-program-new/components/speaker/speaker.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';

@Component({
  components: {
    iconUnFavorite,
    iconFavorite,
    Speaker,
  }
})
export default class ProgramCard extends Vue {

  @Getter('promoProgramStore/floatingPrograms') floatingPrograms: TFloatingProgramCard[];

  @Prop({ type: Object })
  public readonly programSlot: TProgramSlot;

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  @Prop({ type: Boolean })
  public readonly disableDynamicHeight: boolean;

  @Prop({ type: String, default: '#d8d8d8' })
  public readonly cardColor: string;

  public baseSlotHeight: number = BASE_PROGRAM_SLOT_HEIGHT;
  public isFavorite: boolean = false;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  // public get program(): TConferenceProgram {
  //   return (this.programSlot && this.programSlot.program) || null;
  // }

  public get isOpened(): boolean {
    return !!(this.floatingPrograms.find(card => card.program && card.program.id === this.program.id));
  }

  public get totalHeight(): string {

    if (this.disableDynamicHeight) {
      return '';
    } else {
      return 'height:' + (this.cardHeight < 56 ? 56 : this.cardHeight) + 'px';
    }
  }

  public get programCardClasses(): { [key: string]: boolean } {
    return {
      'program-card-opened': this.isOpened,
      'program-card-slotspan-1': this.timeRowsCount === 1,
      'program-card-slotspan-2': this.timeRowsCount === 2,
      'program-card-slotspan-3': this.timeRowsCount === 3,
      'program-card-slotspan-4plus': this.timeRowsCount >= 4,
    };
  }

  public get programTimeStart(): string {
    const date: Date = new Date(((this.program && this.program.date_start) || (new Date())));
    return DateTimeHelper.dateToHoursAndMinutes(date);
  }

  public get programTimeEnd(): string {
    const date: Date = new Date(((this.program && this.program.date_end) || (new Date())));
    return DateTimeHelper.dateToHoursAndMinutes(date);
  }

  public get formattedTime(): string {
    return `${this.programTimeStart} - ${this.programTimeEnd}`;
  }

  public get timeRowsCount(): number {
    if (this.program && this.program.date_start) {
      const dateFrom = new Date(this.program.date_start);
      const dateTo = new Date(this.program.date_end);

      const nextDayStart: Date = new Date(new Date(this.programSlot.dateStart).setHours(0, 0, 0, 0) + 86400 * 1000);
      const slotsLeftInCurrentDay = (nextDayStart.getTime() - this.programSlot.dateStart.getTime()) / 1000 / 60 / PROGRAM_SLOT_DURATION_MINUTES;

      let slotsBeforeToday = 0;
      if (dateFrom.getTime() < this.programSlot.dateStart.getTime()) {
        slotsBeforeToday = (this.programSlot.dateStart.getTime() - dateFrom.getTime()) / 1000 / 60 / PROGRAM_SLOT_DURATION_MINUTES;
      }
      const sessionDurationInSlots = (dateTo.getTime() - dateFrom.getTime()) / 1000 / 60 / PROGRAM_SLOT_DURATION_MINUTES;
      return Math.min(sessionDurationInSlots, slotsLeftInCurrentDay) - slotsBeforeToday;
    }

    return 1;
  }

  public get descriptionLineClamp(): number {
    return this.timeRowsCount > 4 ? 3 : 1;
  }

  public get titleLineClamp(): number {
    return this.timeRowsCount > 1 ? 2 : 1;
  }

  public get isProgramDescriptionVisible(): boolean {
    return (!this.programSpeakers || !this.programSpeakers.length) || this.timeRowsCount >= 8;
  }

  public get programTitle(): string {
    return this.program && this.program.title;
  }

  public get programDescription(): string {
    return this.program && this.program.description;
  }

  public get programSpeakersDisplayLength(): number {
    return this.timeRowsCount <= 2 ? 2 : 4;
  }

  public get programSpeakers(): TContact[] {
    return (this.program && this.program.speakers && this.program.speakers.slice(0, this.programSpeakersDisplayLength)) || [];
  }

  public get moreSpeakersHtml(): string {
    if (!this.programSlot || !this.program || !this.program.speakers || !this.program.speakers.length) {
      return '';
    }
    const totalSpeakers: number = this.program.speakers.length;
    const speakersNumber: number = totalSpeakers - this.programSpeakersDisplayLength;
    return this.$t('floatingProgramCard.moreSpeakers', { speakersNumber: '<span>+' + speakersNumber + '</span>' }) as string;
  }

  public mounted(): void {
    if (this.programSlot && this.programSlot.program) {
      this.isFavorite = this.programSlot.program.is_favorite;
    }
  }

  public get cardHeight(): number {
    const offsetTop = 1;
    const offsetBottom = 7;
    const offsets: number = offsetTop + offsetBottom;
    if (!this.program) {
      return this.baseSlotHeight - offsets;
    }

    return (this.timeRowsCount * this.baseSlotHeight) - offsets;
  }

  public toggleFavorite(): void {
    this.isFavorite = !this.isFavorite;
    this.$store.dispatch('promoProgramStore/toggleFavorite', {
      eventId: this.eventId,
      conferenceRoomId: this.program.conference_id,
      programId: this.program.id,
      isFavorite: this.isFavorite,
    });
    this.$emit('isFavoriteChange', this.isFavorite);
  }

}
