


import { Vue, Component, Prop } from 'vue-property-decorator';

import EwCalendar from '@/_modules/ew-calendar/ew-calendar.vue';
import { TScheduleDay } from '@/_modules/ew-calendar/types/ew-calendar.type';
import IconSelectArrowUpDown from '@/_modules/icons/components/icon-select-arrow-up-down.vue';

@Component({
  components: {
    IconSelectArrowUpDown,
    EwCalendar
  }
})
export default class EwSelectCalendar extends Vue {

  public isFocused: boolean = false;

  @Prop({ type: String, default: '' })
  public readonly selectedDate: string = '';

  public get isSelectedDateToday(): boolean {
    if (!this.selectedDate) {
      return false;
    }
    const today = new Date();
    const selectedDate = this.$moment(this.selectedDate).clone().toDate();
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);
    return today.getTime() === selectedDate.getTime();
  }

  public onSelectFocus(): void {
    this.isFocused = true;
  }

  public onSelectBlur(): void {
    this.isFocused = false;
  }

  public onCalendarDayChange(day: TScheduleDay): void {
    this.$emit('targetScheduleDay', day);
  }

}
