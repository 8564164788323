


import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { TProgramSlot } from '@/_modules/promo-program-new/components/program-viewer/program-viewer.vue';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { TAddFloatingProgramCardParams } from '@/_modules/promo-program/store/promo-program.store';
import ProgramCard from '@/_modules/promo-program-new/components/program-card/program-card.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';
import ResponsivenessHelper from '@/_helpers/responsiveness.helper';

@Component({
  components: {
    ProgramCard
  }
})
export default class ProgramList extends Vue {

  @Action('promoProgramStore/addFloatingProgram') addFloatingProgramCard: (params: TAddFloatingProgramCardParams) => void;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('calendarStore/getMarkedDates') markedDates: string[];

  @Prop({ type: Array, default: null })
  public columnsOfProgramPage: TConferenceRoom[];

  @Prop({type: Object, default: () => ({})})
  readonly roomColors: { [confRoomId: number]: string };

  @Prop({type: Boolean, default: false})
  readonly isListTypeMy: boolean;

  @Prop({type: Number, default: 1})
  readonly numberOfColumnsInPage: number;

  public programSlots: TProgramSlot[] = [];

  public get programGridTemplateColumnsCSSRule(): string {
    const colFrWidth = (100 / this.numberOfColumnsInPage).toFixed(2) + 'fr';
    return 'repeat(' + this.numberOfColumnsInPage + ', ' + colFrWidth + ')';
  }

  @Watch('columnsOfProgramPage', {immediate: true})
  public onColumnsOfProgramPageChange(newVal: TConferenceRoom[]): void {
    if (newVal) {
      this.initCurrentPage();
    }
  }

  @Watch('numberOfColumnsInPage')
  public onNumberOfColumnsInPageChange(): void {
    this.initCurrentPage();
  }

  public initCurrentPage(): void {
    this.createProgramSlots();

    this.$nextTick(() => {
      const futureSlots = document.querySelectorAll('.program-slot-future');
      this.scrollToGivenSlot(futureSlots[0]);
    });
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get markedDatesInsideEventDates(): string[] {
    if (!this.event || !this.markedDates || !this.markedDates.length) {
      return [];
    }
    const eventStartDateMS = (new Date(this.event.date_start)).setHours(0, 0, 0, 0);
    const eventEndDateNextDay = new Date(this.event.date_end);
    eventEndDateNextDay.setHours(0, 0, 0, 0);
    eventEndDateNextDay.setDate(eventEndDateNextDay.getDate() + 1);
    const eventEndDateNextDayMS = eventEndDateNextDay.getTime();

    return this.markedDates.filter(markedDate => {
      const markedDateMS = (new Date(markedDate)).getTime();
      return markedDateMS >= eventStartDateMS && markedDateMS < eventEndDateNextDayMS;
    });
  }

  public onProgramCardClick(e: PointerEvent, program: TConferenceProgram): void {
    const left: number = e.clientX;
    const top: number = e.clientY;
    if (ResponsivenessHelper.isScreenWidthLessOrEqualTo(800)) {
      this.navigateToProgramPage(program);
    } else {
      this.addFloatingProgramCard({
        top,
        left,
        program,
      });
    }
  }

  public navigateToProgramPage(program: TConferenceProgram): void {
    if (!program || !program.date_start) {
      return;
    }
    this.$router.push({
      name: 'promo-program-page',
      params: {
        eventId: '' + this.eventId,
        programDate: '' + DateTimeHelper.dateToStringAsYMD(new Date(program.date_start)),
        programId: '' + program.id,
      }
    });
  }

  // TODO: test
  public onProgramCardIsFavoriteChange(newVal: boolean, program: TConferenceProgram): void {
    program.is_favorite = newVal;
  }

  public isVisibleInCurrentViewType(program: TConferenceProgram): boolean {
    if (this.isListTypeMy) {
      return program.is_favorite;
    }
    return true;
  }

  public isProgramDayDifferentFromPrevious(program: TProgramSlot, index: number): boolean {
    if (index === 0) {
      return true;
    }

    const prevProgramDate = new Date(this.programSlots[index - 1] && this.programSlots[index - 1].dateStart);
    const programDate = new Date(program.dateStart);

    return prevProgramDate.setHours(0, 0, 0, 0) !== programDate.setHours(0, 0, 0, 0);
  }

  public isProgramCardPast(program: TConferenceProgram): boolean {
    const programEndDate: Date = new Date(program.date_end);
    const now: Date = new Date();
    return programEndDate.getTime() < now.getTime();
  }

  public isProgramSlotFuture(slot: TProgramSlot): boolean {
    return slot.programs.length && !!slot.programs.find(item => +item.date_end >= (new Date()).getTime() - (1000 * 60 * 30));
  }

  public scrollToGivenSlot(programSlotElement: HTMLElement | Element): void {
    // N.B.: getBoundingClientRect() returns numbers relative to viewport
    if (!programSlotElement) {
      return;
    }
    const programList = document.querySelector('.program-viewer-body');
    try {
      const targetRect: DOMRect = programSlotElement.getBoundingClientRect();
      const viewerRect: DOMRect = programList.getBoundingClientRect();
      const viewerCompStyles: CSSStyleDeclaration = window.getComputedStyle(programList);
      const viewerPaddingTop: number = parseInt(viewerCompStyles.getPropertyValue('padding-top'), 10);
      const targetTop: number = targetRect.top - viewerRect.top - viewerPaddingTop + programList.scrollTop;

      programList.scroll({
        left: 0,
        top: targetTop,
        behavior: 'smooth',
      });
    } catch { /* ignore */ }
  }

  public createProgramSlots(): void {
    this.programSlots = [];

    if (!this.columnsOfProgramPage || !this.columnsOfProgramPage.length) {
      return;
    }

    const numberOfSlotRows = this.markedDatesInsideEventDates.length;

    for (let i = 0; i < numberOfSlotRows; i++) {
      const dateOfTheRow = new Date(this.markedDatesInsideEventDates[i]);
      dateOfTheRow.setHours(0, 0, 0, 0);
      const slotRow: TProgramSlot[] = [];
      for (let j = 0; j < this.numberOfColumnsInPage; j++) {
        const columnOfPage = this.columnsOfProgramPage[j];
        slotRow.push({
          dateStart: new Date(dateOfTheRow),
          conferenceRoomId: (columnOfPage && columnOfPage.id) || null,
          conferenceRoomTitle: '',
          program: null,
          programs: this.findDayProgramsInRoom(dateOfTheRow, columnOfPage || null) || [],
          isOverlappedByAnotherSession: false,
        });
      }
      if (slotRow.find(slot => slot.programs && slot.programs.length)) {
        this.programSlots.push(...slotRow);
      }
    }
  }

  public findDayProgramsInRoom(day: Date, confRoom: TConferenceRoom): TConferenceProgram[] {
    if (!day || !confRoom || !confRoom.programs || !confRoom.programs.length) {
      return [];
    }
    const dayStartTimestamp = day.setHours(0, 0, 0, 0);

    return confRoom.programs.filter(program => {
      return program.date_start && dayStartTimestamp === (new Date(program.date_start)).setHours(0, 0, 0, 0);
    });
  }

  // Used when on changing view type (all / my programs), some days become empty
  // We don't want to show empty days
  public isProgramSlotInEmptyRow(slotIndex: number): boolean {
    const rowStartIndex = slotIndex - slotIndex % this.numberOfColumnsInPage;
    const rowEndIndex = rowStartIndex + this.numberOfColumnsInPage - 1;
    let result = true;
    for (let i = rowStartIndex; i <= rowEndIndex; i++) {
      if (this.programSlots[i].programs && this.programSlots[i].programs.length) {
        result = false;
        break;
      }
    }
    return result;
  }

  public onProgramSlotDateClick(date: Date): void {
    this.$emit('dateChangeRequested', date);
  }

}
